[data-amplify-authenticator] [data-amplify-container] {
  width: auto;
  background-color: #5f7d95;
  background-image: url("../../../public/static/world-dods.png");
}

[data-amplify-container] {
  font-family: 'Poppins', 'Note_Sans_JP';
  display: block;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color:rgba(0,0,0,0);
  box-shadow: none;
  border: none;
}

[data-amplify-container] h1 {
  font-size: 48px;
}

.amplify-input {
  background-color:#FFFFFF;
  border-radius: 35px;
}

.amplify-button[data-variation=primary] {
  margin-top: 50px;
  background-color:rgba(0,0,0,0);
  background:url("../../../public/static/login.png");
  color: #5f7d95;
  width: 300px;
  height:  70px;
  font-size: 24px;
  margin-left: auto;
  margin-right: auto;
  background-size: contain;
  background-repeat: no-repeat;
}

.amplify-button[data-variation=primary]:hover {
  background-color:rgba(0,0,0,0);
  color: #5f7d95;
}

[data-amplify-router] {
  display: flex;
}

[data-amplify-router-content] {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}

[data-amplify-router-content] input {
  height: 70px;
  padding: 16px 40px;
  font-size: 24px;
}

.amplify-field__show-password {
  display: none;
}

.amplify-field-group :not(:last-child) .amplify-input {
  border-end-end-radius:  35px;
  border-start-end-radius: 35px;
}

.amplify-image {
  animation-name: fadeUpAnime;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  padding-top: 40vh;
  width: 122px;
}

@keyframes fadeUpAnime{
  from {
    padding-top: 40vh;
    width: 122px;
  }

  to {
    padding-top: 20vh;
    width: 88px;
  }
}

[data-amplify-router] {
  animation-name: fadeInAnime;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity:0;
}

@keyframes fadeInAnime{
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
